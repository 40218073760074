.provider_wrap{
    padding: 10px 20px;
}
.heading {
    font-size: 28px;
    font-weight: 500;
}
.filterBar{
    padding: 10px 20px;
    /* width: 100%; */
    margin: 20px 0 0 0;
    display: flex;
    justify-content:space-between;
}