.login_wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.centered_div{
    width: 480px;
    padding: 30px 20px;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #282828;
}