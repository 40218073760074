.provider_wrap{
    padding: 10px 20px;
}
.heading {
    font-size: 28px;
    font-weight: 500;
}
.filterBar{
    padding: 10px 20px;
    /* width: 100%; */
    background-color: #f6f6f6;
    margin: 20px 0 0 0;
}
.contentBody{
    padding: 15px;
    margin-top: 20px;   
    background: #f6f6f6;
}
.search {
    align-items: center;
    justify-content: center;
}
.pavatar{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.textDetails{
    text-align: center;
    color: #282828;
    font-family: sans-serif;
}
.textrow{
    display: block;
    padding: 5px;
}
.textrow span{
    width: 100%;
    display: block;
    text-align: left;
}
.textrow span:nth-child(1){
    font-size: 0.8rem;
    color: #808080;
}
.textrow span:nth-child(2){
    font-size: 0.9rem;
}
.specialisationChip{
    margin: 2px 2px;
    border-color: #a8a8a8 !important
}
.specialisationChip span {
    color: #4f4f4f !important; 
}
.available{
    height: 10px;
    width: 10px;
    border-radius: 10px;
    position: absolute;
    bottom: 8px;
    left: 60%;
    z-index: 10;
}
.notAvailableColor{
    background: #d8d8d8;
}
.availableColor{
    background: #44b700;
}