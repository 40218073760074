.order_wrapper{
    padding: 10px 20px;
}
.notAvail{
    color: #b27300
}
.perPage{
    /* justify-content: end; */
}
.filterBar{
    display: flex;
    justify-content: space-between;
}