@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    padding: 0px;
    margin: 0px;
}
.main_container {
    display: flex;
    flex-direction: row;
}
.left_menu{
    background: #e3f1ff;
    height: 100vh;
    position: fixed;
    width: 180px;
    border-right: 1px solid #bcdeff;
    z-index: 1;
}
.left_menu ul{
    /* display: block; */
    padding: 0px;
}
.left_menu ul a {
    font-family: Helvetica, sans-serif;
    font-size: 15px;
    color: #3e5060;
    /* font-weight: 500; */
    text-decoration: none;
}
.left_menu ul li {
    list-style: none;
    padding: 15px 20px;
}
.left_menu ul li:hover{
    cursor: pointer;
    background: #dae7f5
}

.left_menu ul li:hover .left_menu a{
    color:#282828 ;
}
.logoutWrap {
    /* position: absolute; */
    /* bottom: 50px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.tab-active{
    background: #bbd4ec;
}
.right_menu{
    flex: 7;
    position: relative;
    margin-left: 180px;
    overflow-x: hidden;
    min-height: 100vh;
}
.home_wrapper{
    padding: 10px 40px;
}   

.logoImg{
    width: 80%;
    display: block;
    margin: 0px auto;
    padding: 10px 0px;
}

.fullScreenLoader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }